import React from 'react';
import Layout from '../components/layout';

export default function Contact() {
  return (
    <Layout>
      <h1>Contact</h1>
    </Layout>
  );
}
